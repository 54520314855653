.task-setup-form-container {
  margin-top: 4rem;

  .form-card {
    width: 100%;
    margin-top: 2rem;
    border-radius: 1rem;
    padding: 1rem 4rem 2rem 4rem;

    @media (max-width: $screen-md) {
      padding: 1rem 2rem 2rem 2rem;
    }
  }

  form {
    padding: 0rem 2rem 2rem 2rem;
  }

  .form-group {
    .app-grid-container {
      .search-input-group {
        padding-right: 6rem;
        padding-left: 6rem;
        padding-bottom: 2rem;
      }
    }
  }
}

.task-setup-button-container {
  padding: 1rem 0rem;
}
